<template>
    <section
        class="o-flexible-module"
        :class="{ 'o-flexible-module--flush': settings.spacing == 'flush' }">
        <div class="grid-container" v-if="settings.showComponentTitle">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">
                        Item Scroller
                    </div>
                </div>
            </div>
        </div>

        <div :class="`c-item-scroller c-item-scroller--${settings.theme}`">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <h2 class="c-item-scroller__title" data-aos="fade-up" v-html="title"></h2>

                        <div class="s-swiper js-cursor-scroll">
                            <swiper-container
                                :breakpoints="{
                                    768: {
                                        slidesPerView: 2,
                                        slidesPerGroup: 2,
                                        spaceBetween: 40
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3,
                                        spaceBetween: 40
                                    }
                                }"
                                :space-between="0"
                                :slides-per-view="1"
                                :pagination="{
                                    clickable: true,
                                    enabled: true
                                }">
                                <template v-if="contentOptions == 'latest'">
                                    <swiper-slide>
                                        <div class="c-item-scroller__slide is-post is-dark">
                                            <NuxtLink
                                                :to="`/news-blog/${$convertUrl(postsData.edges[0].node.slug)}`"
                                                :title="postsData.edges[0].node.title">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ postsData.edges[0].node.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    v-if="postsData.edges[0].node.post.excerpt"
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-html="postsData.edges[0].node.post.excerpt"></div>

                                                <div class="c-item-scroller__slide-type">
                                                    News
                                                </div>
                                            </NuxtLink>
                                        </div>
                                    </swiper-slide>

                                    <swiper-slide v-if="useMainStore().latestEvent.slug">
                                        <div class="c-item-scroller__slide is-event is-dark">
                                            <NuxtLink
                                                :to="`/events/${$convertUrl(useMainStore().latestEvent.slug)}`"
                                                :title="useMainStore().latestEvent.title">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ useMainStore().latestEvent.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="useMainStore().latestEvent.event.excerpt"
                                                    v-html="useMainStore().latestEvent.event.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Event
                                                </div>
                                            </NuxtLink>
                                        </div>
                                    </swiper-slide>

                                    <swiper-slide v-else>
                                        <div class="c-item-scroller__slide is-event is-dark">
                                            <NuxtLink
                                                :to="`/events/${$convertUrl(useMainStore().mostRecentEvent.slug)}`"
                                                :title="useMainStore().mostRecentEvent.title">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ useMainStore().mostRecentEvent.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="useMainStore().mostRecentEvent.event.excerpt"
                                                    v-html="useMainStore().mostRecentEvent.event.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Event
                                                </div>
                                            </NuxtLink>
                                        </div>
                                    </swiper-slide>

                                    <swiper-slide v-if="resourcesData.edges.length">
                                        <div class="c-item-scroller__slide is-resource is-dark">
                                            <NuxtLink
                                                :to="resourcesData.edges[0].node.resource.file.mediaItemUrl"
                                                v-if="resourcesData.edges[0].node.resource.resourceType == 'file'">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ resourcesData.edges[0].node.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="resourcesData.edges[0].node.resource.excerpt"
                                                    v-html="resourcesData.edges[0].node.resource.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Resource
                                                </div>
                                            </NuxtLink>

                                            <NuxtLink
                                                :to="resourcesData.edges[0].node.resource.link.url"
                                                v-else-if="resourcesData.edges[0].node.resource.resourceType == 'link'">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ resourcesData.edges[0].node.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="resourcesData.edges[0].node.resource.excerpt"
                                                    v-html="resourcesData.edges[0].node.resource.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Resource
                                                </div>
                                            </NuxtLink>

                                            <NuxtLink
                                                to=""
                                                v-else-if="resourcesData.edges[0].node.resource.resourceType == 'gated'">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ resourcesData.edges[0].node.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="resourcesData.edges[0].node.resource.excerpt"
                                                    v-html="resourcesData.edges[0].node.resource.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Resource
                                                </div>
                                            </NuxtLink>
                                        </div>
                                    </swiper-slide>

                                    <swiper-slide>
                                        <div class="c-item-scroller__slide is-client-story is-dark">
                                            <NuxtLink
                                                :to="`/client-stories/${$convertUrl(clientStoriesData.edges[0].node.slug)}`"
                                                :title="clientStoriesData.edges[0].node.title">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ clientStoriesData.edges[0].node.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="clientStoriesData.edges[0].node.clientStory.excerpt"
                                                    v-html="clientStoriesData.edges[0].node.clientStory.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Client Story
                                                </div>
                                            </NuxtLink>
                                        </div>
                                    </swiper-slide>
                                </template>

                                <template v-if="contentOptions == 'manual'">
                                    <swiper-slide
                                        v-for="(item, index) in items"
                                        :key="index">
                                        <div
                                            v-if="item.contentTypeName == 'post'"
                                            class="c-item-scroller__slide is-post is-dark">
                                            <NuxtLink
                                                :to="`/news-blog/${$convertUrl(item.slug)}`"
                                                :title="item.title">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ item.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    v-if="item.post.excerpt"
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-html="item.post.excerpt"></div>

                                                <div class="c-item-scroller__slide-type">
                                                    News
                                                </div>
                                            </NuxtLink>
                                        </div>

                                        <div
                                            v-if="item.contentTypeName == 'event'"
                                            class="c-item-scroller__slide is-event is-dark">
                                            <NuxtLink
                                                :to="`/events/${$convertUrl(item.slug)}`"
                                                :title="item.title">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ item.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="item.event.excerpt"
                                                    v-html="item.event.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Event
                                                </div>
                                            </NuxtLink>
                                        </div>

                                        <div
                                            v-if="item.contentTypeName == 'person'"
                                            class="c-item-scroller__slide is-person">
                                            <div class="c-item-scroller__slide-hero">
                                                <div class="c-item-scroller__slide-media">
                                                    <img
                                                        :src="item.person.featuredImage.sourceUrl"
                                                        :srcset="item.person.featuredImage.srcSet"
                                                        :sizes="item.person.featuredImage.sizes"
                                                        :alt="item.title"
                                                        :title="item.title" />
                                                </div>
                                            </div>

                                            <div class="c-item-scroller__slide-title">
                                                {{ item.title }}
                                            </div>

                                            <div
                                                v-if="item.person.position"
                                                class="c-item-scroller__slide-subtitle">
                                                {{ item.person.position }}
                                            </div>

                                            <div class="c-item-scroller__slide-excerpt s-wp-cms"
                                                v-if="item.person.description"
                                                v-html="item.person.description">
                                            </div>
                                        </div>

                                        <div
                                            v-if="item.contentTypeName == 'resource'"
                                            class="c-item-scroller__slide is-resource is-dark">
                                            <NuxtLink
                                                :to="item.resource.file.mediaItemUrl"
                                                v-if="item.resource.resourceType == 'file'">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ item.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="item.resource.excerpt"
                                                    v-html="item.resource.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Resource
                                                </div>
                                            </NuxtLink>

                                            <NuxtLink
                                                :to="item.resource.link.url"
                                                v-else-if="item.resource.resourceType == 'link'">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ item.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="item.resource.excerpt"
                                                    v-html="item.resource.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Resource
                                                </div>
                                            </NuxtLink>

                                            <NuxtLink
                                                to=""
                                                v-else-if="item.resource.resourceType == 'gated'">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ item.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="item.resource.excerpt"
                                                    v-html="item.resource.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Resource
                                                </div>
                                            </NuxtLink>
                                        </div>

                                        <div
                                            v-if="item.contentTypeName == 'client_story'"
                                            class="c-item-scroller__slide is-client-story is-dark">
                                            <NuxtLink
                                                :to="`/client-stories/${$convertUrl(item.slug)}`"
                                                :title="item.title">
                                                <div class="c-item-scroller__slide-hero">
                                                    <div class="c-item-scroller__slide-title">
                                                        {{ item.title }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="c-item-scroller__slide-excerpt s-wp-cms"
                                                    v-if="item.clientStory.excerpt"
                                                    v-html="item.clientStory.excerpt">
                                                </div>

                                                <div class="c-item-scroller__slide-type">
                                                    Client Story
                                                </div>
                                            </NuxtLink>
                                        </div>
                                    </swiper-slide>
                                </template>

                                <template v-else-if="contentOptions == 'people'">
                                    <swiper-slide v-for="(person, index) in people" :key="index">
                                        <div class="c-item-scroller__slide is-person">
                                            <div class="c-item-scroller__slide-hero">
                                                <div class="c-item-scroller__slide-media">
                                                    <img
                                                        :src="person.person.featuredImage.sourceUrl"
                                                        :srcset="person.person.featuredImage.srcSet"
                                                        :sizes="person.person.featuredImage.sizes"
                                                        :alt="person.title"
                                                        :title="person.title" />
                                                </div>
                                            </div>

                                            <div class="c-item-scroller__slide-title">
                                                {{ person.title }}
                                            </div>

                                            <div
                                                v-if="person.person.position"
                                                class="c-item-scroller__slide-subtitle">
                                                {{ person.person.position }}
                                            </div>

                                            <div class="c-item-scroller__slide-excerpt s-wp-cms"
                                                v-if="person.person.description"
                                                v-html="person.person.description">
                                            </div>
                                        </div>
                                    </swiper-slide>
                                </template>

                                <template v-else>
                                    <template v-for="(content, index) in curatedContent">
                                        <swiper-slide v-if="content.resource_type !== 'gated'">
                                            <div
                                                :class="`is-${content.post_type.replace('_', '-')}`"
                                                class="c-item-scroller__slide is-dark">
                                                <NuxtLink
                                                    :to="getContentLink(content)"
                                                    :title="content.title">
                                                    <div class="c-item-scroller__slide-hero">
                                                        <div class="c-item-scroller__slide-title">
                                                            {{ content.title }}
                                                        </div>

                                                        <div
                                                            v-if="content.item_scroller_background"
                                                            :class="content.item_scroller_background_overlay ? `has-overlay overlay-${content.item_scroller_background_overlay}` : ''"
                                                            class="c-item-scroller__slide-bg">
                                                            <img :src="content.item_scroller_background.url" />
                                                        </div>
                                                    </div>

                                                    <div
                                                        v-if="content.excerpt"
                                                        class="c-item-scroller__slide-excerpt s-wp-cms"
                                                        v-html="content.excerpt">
                                                    </div>

                                                    <div class="c-item-scroller__slide-type">
                                                        {{ getSlideType(content.post_type) }}
                                                    </div>
                                                </NuxtLink>
                                            </div>
                                        </swiper-slide>
                                    </template>
                                </template>
                            </swiper-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { useMainStore } from '~/store';
    import postsQuery from '~/queries/loops/posts';
    import resourcesQuery from '~/queries/loops/resources';
    import clientStoriesQuery from '~/queries/loops/clientStories';

    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        availablePostTypes: {
            type: Array,
            required: false
        },
        itemScrollerCategory: {
            type: Array,
            required: false
        },
        items: {
            type: Object,
            required: false
        },
        people: {
            type: Object,
            required: false
        },
        contentOptions: {
            type: String,
            required: false
        },
        settings: {
            theme: {
                type: String,
                required: true
            },
            showComponentTitle: {
                type: Boolean,
                required: true,
            },
            spacing: {
                type: String,
                required: true
            }
        }
    });

    const route = useRoute();
    const config = useRuntimeConfig();
    const { $convertUrl } = useNuxtApp();
    const postsData = useState('postsData', () => null);
    const resourcesData = useState('resourcesData', () => null);
    const clientStoriesData = useState('clientStoriesData', () => null);
    const curatedContent = useState('curatedContent', () => []);

    const variables = {
        slug: process.env.NODE_ENV == 'development' ? route.path : 'cms' + route.path
    };

    const getContentLink = (content) => {
        if (content.post_type == 'resource') {
            return content.resource_file && content.resource_type == 'file' ? content.resource_file.url : content.resource_link.url;
        };

        return $convertUrl(content.url);
    };

    const getSlideType = (postType) => {
        switch(postType) {
            case 'post':
                return 'News';
                break;
            case 'event':
                return 'Event'
                break;
            case 'resource':
                return 'Resource';
                break;
            case 'client_story':
                return 'Client Story';
                break;
            case 'page':
                return 'Page';
                break;
            default:
                return '';
        };
    };

    const getLatestPost = async () => {
        const postsGqlQuery = gql(postsQuery(1, null, null));
        const { data, error } = await useAsyncQuery(postsGqlQuery, { ...variables });

        if (!(data && data.value && data.value.posts)) {
            throw createError({ statusCode: 404, statusMessage: 'Page Not Found' });
        };

        postsData.value = data.value.posts;
    };

    const getLatestResource = async () => {
        const resourcesGqlQuery = gql(resourcesQuery(5, null, null));
        const { data, error } = await useAsyncQuery(resourcesGqlQuery, { ...variables });

        if (!(data && data.value && data.value.resources)) {
            throw createError({ statusCode: 404, statusMessage: 'Page Not Found' });
        };

        // Filter out gated resources
        data.value.resources.edges = data.value.resources.edges.filter(edge => edge.node.resource.resourceType !== 'gated');

        resourcesData.value = data.value.resources;
    };

    const getLatestClientStory = async () => {
        const clientStoriesGqlQuery = gql(clientStoriesQuery(1, null, null));
        const { data, error } = await useAsyncQuery(clientStoriesGqlQuery, { ...variables });

        if (!(data && data.value && data.value.clientStories)) {
            throw createError({ statusCode: 404, statusMessage: 'Page Not Found' });
        };

        clientStoriesData.value = data.value.clientStories;
    };

    const getCuratedContent = async () => {
        // Make server side fetch POST request to admin ajax (see search template)
        // Pass the required array of categories along with the request
        // Should also pass the current post type and the current topic as results should vary depending on the currently viewing post type.
        // In admin-ajax need to search for all post types that have been assigned any one of these categories
        let request = new FormData();
        request.append('action', 'cisilion_get_curated_content');
        request.append('post_types', props.availablePostTypes);
        request.append('categories', props.itemScrollerCategory);

        const { data, error } = await useFetch(`${config.public.cmsBaseUrl}/wp/wp-admin/admin-ajax.php`, {
            method: 'POST',
            body: request,
        });

        curatedContent.value = JSON.parse(data.value);
    };


    if (props.contentOptions == 'latest') {
        await getLatestPost();
        await getLatestResource();
        await getLatestClientStory();
    } else {
        await getCuratedContent();
    };
</script>
